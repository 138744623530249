import { DiffDOM } from 'diff-dom';


export default class DCTAManager {
  /*
   * Currently primarily a means of encapsulating the DCTAs functionality,
   * but doesn't actually hold any local data, as all DCTA content management
   * is currently handled server-side.  This may change if we restructure
   * to add more DCTA control to the client side
   */
  static updateAll(dctas) {
    /*
     * Main entrypoint for updating ALL DCTAs.
     */
    dctas.map(dcta => DCTAManager.updateDCTA(dcta));
  }

  static updateDCTA(dcta) {
    /*
     * Handles updating a single DCTA.  This involves two steps:
     *   - Inserting the pre-rendered HTML for the widgets, which
     *     is sent from the backend
     *   - adding global styles necessary for the DCTA (also sent
     *     from the backend
     */
    DCTAManager.insertGlobalStyles(dcta.id, dcta.global_styles_string);
    DCTAManager.insertWidgets(dcta.id, dcta.widget_string);
  }

  static insertGlobalStyles(dctaID, stylesString) {
    /*
     * If a DCTA has global styles (i.e. styles that are to be inserted into the head,
     * for the entire document), it will come as a pre-rendered string from the
     * backend.  This function inserts that into head, namespacing the styles with the
     * DCTA id so as to avoid conflicts and allow updates
     */
    const dctaIDString = `dcta-global-styles-${dctaID}`;
    let styleWrapper = document.getElementById(dctaIDString);

    if (!styleWrapper) {
      const head = document.getElementsByTagName('head')[0];
      styleWrapper = document.createElement('style');
      styleWrapper.id = dctaIDString;
      head.appendChild(styleWrapper);
    }
    styleWrapper.innerHTML = stylesString;
  }

  static insertWidgets(dctaID, widgetString) {
    /*
     * If a DCTA has active widgets, they will come as a pre-rendered HTML
     * string from the backend.  This function handles inserting that HTML
     * string into `body`, namespacing with the DCTA id to avoid conflicts
     * and allow updates.
     */
    const dctaIDString = `dcta-widget-${dctaID}`;
    let widgetWrapper = document.getElementById(dctaIDString);

    if (!widgetWrapper) {
      const body = document.getElementsByTagName('body')[0];
      widgetWrapper = document.createElement('div');
      widgetWrapper.id = dctaIDString;
      widgetWrapper.className = 'dcta-widget-wrapper';
      body.appendChild(widgetWrapper);
      widgetWrapper.innerHTML = widgetString;
    } else {
      const dd = new DiffDOM();
      const diff = dd.diff(
        widgetWrapper, `<div id="${dctaIDString}" class="dcta-widget-wrapper">${widgetString}</div>`,
      );
      dd.apply(widgetWrapper, diff);
    }
  }
}
