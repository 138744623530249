/* eslint-disable no-unused-vars */

/*
 * Returned when the username or campaign in the target URL doesn't
 * correspond to a matching object. Appropriate action should be
 * setting reconnect to `false` so we don't endlessly try to hit
 * a non-functional link.
 */
export const NO_MATCH = 4000;

/*
 * Returned when the user hasn't set up his/her browsersource.
 * Appropriate action should be re-routing to the legacy
 * browsersource system
 */
export const BROWSERSOURCE_NOT_SETUP = 4001;
