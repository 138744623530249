import '../sass/styles.sass';

import Browsersource from './browsersource';
import onload from './onload';
import { targetUrl } from './url';

onload();

if (targetUrl) {
  const browsersource = new Browsersource(targetUrl);
  browsersource.run();
} else {
  // eslint-disable-next-line no-console
  console.log('This is not a valid browsersource URL');
}
