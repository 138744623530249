import advocateIcon from '../img/advocate-icon';

export default class TestPattern {
  constructor() {
    this.visible = false;
  }

  build() {
    /*
     * Creates the test pattern and adds it to the body
     */
    const body = document.getElementsByTagName('body')[0];
    const pattern = document.createElement('div');
    pattern.id = 'test-pattern';

    const checkerboard = document.createElement('div');
    checkerboard.className = 'checkerboard';

    pattern.innerHTML = advocateIcon;
    pattern.appendChild(checkerboard);
    body.appendChild(pattern);

    this.visible = true;
  }

  remove() {
    /*
     * Fade out the pattern then remove it
     */
    const pattern = document.getElementById('test-pattern');
    pattern.classList.add('remove');

    this.visible = false;
    setTimeout(() => pattern.parentNode.removeChild(pattern), 2000);
  }

  toggle() {
    if (this.visible) this.remove();
    else this.build();
  }

  flash() {
    if (!this.visible) this.build();

    setTimeout(() => this.remove(), 3000);
  }
}
