const advocateIcon = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="187px" height="187px" viewBox="0 0 187 187" enable-background="new 0 0 187 187" xml:space="preserve">
<rect fill="#F9427F" width="187" height="187"></rect>
<g><g>
<path fill="#FFFFFF" d="M50.756,123.109L73.11,60H56.188l-26.699,67h16.223C47.977,127,49.996,125.244,50.756,123.109z"></path>
<path fill="#FFFFFF" d="M147.199,69.618c-3.197-2.895-7.119-5.352-11.765-6.98C130.787,61.009,125.51,60,119.596,60h-7.149h-1.632
H95v53.41L74.795,63.917l-8.897,24.707l12.335,34.69c0.764,2.129,2.723,3.686,4.982,3.686h11.313h5.372h10.915h1.632h6.788
c5.975,0,11.297-0.966,15.975-2.625c4.674-1.659,8.627-4.021,11.855-6.947c3.227-2.926,5.67-6.443,7.389-10.487
c1.72-4.041,2.547-8.479,2.547-13.248v-0.361V93.15c0-4.766-0.813-9.156-2.502-13.168
C152.809,75.97,150.397,72.514,147.199,69.618z M138,93.602v0.09c0,5.371-1.586,9.285-4.902,12.182
c-3.32,2.896-7.68,4.126-13.229,4.126H112V77h7.868c5.55,0,9.909,1.302,13.229,4.229c3.316,2.927,4.902,6.884,4.902,12.193V93.602
z"></path>
</g>
</g>
</svg>`;

export default advocateIcon;
