// Hook for any setup processing: currently used for using Search Params for customization
const onload = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const bg = searchParams.get('bg');

  if (bg) {
    document.body.style.background = bg;
  }
};

export default onload;
